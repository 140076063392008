import { Grid, CardHeader, CardContent, Box, TextField } from "@mui/material";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { ProfileAttribute } from "../../utils/Schema";
import { useLocation } from "react-router-dom";
import apiAuthLogin from "../../services/authServiceLogin";
import SaveButton from "../../components/SaveButton";

function AddEditProfileAttribute() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const location = useLocation();
  const { slug ,title } = location.state || {}; 

  const { id } = useParams();
  const fetchTaskData = useCallback(async () => {
    if (id) {
      setLoading(true);
        try {
          const response = await apiAuthLogin.updateProfileFeature({ id });
          const resData = response?.data?.data;
          setData(resData);
        } catch (error) {
          errorHandler(error);
        } finally {
          setLoading(false);
        }
    }
  }, [id]);

  useEffect(() => {
    
    fetchTaskData();
  }, [fetchTaskData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id:id||"",
      title: data?.title || "",
      slug:slug,
    },
    validationSchema: ProfileAttribute,
    onSubmit: async (values) => {
      setLoading(true);
      try {

        const response = id
          ? await apiAuthLogin.updateProfileFeature(values)
          : await apiAuthLogin.addProfileFeature(values);
        console.log(response)
        toast.success(response?.data?.message);
        navigate(-1);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    },
  });
  
  return (
    <Box>
      <Helmet>
        <title>{id ? "Edit" : "Add"} {title}</title>
      </Helmet>
      <CardLayout>
        <CardHeader title={`${id ? "Edit" : "Add"} ${title}`} />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    label="title"
                    required
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    name="title"
                    error={Boolean(formik.touched.title && formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                  />             

                  <Grid item xs={12} md={12}>
                    <SaveButton loading={formik.isSubmitting} id={id} />                   
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
}

export default AddEditProfileAttribute;