import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { Grid, CardHeader, CardContent, Box, TextField, FormControl, InputLabel, MenuItem, Select, FormHelperText, IconButton, InputAdornment, Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import SubscriptionServiceApi from "../../services/subscriptionService";
import { subscriptionValidation } from "../../utils/Schema";
import { toast } from "react-toastify";
import { IMAGE_BASE } from "../../utils/constants";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";

const SubscriptionTypeOptions = [
  { _id: "1", label: "Weekly Plan", value: "weekly" },
  { _id: "2", label: "Monthly Plan", value: "monthly" },
  { _id: "3", label: "Yearly Plan", value: "yearly" },
];

function AddSubscription() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { id } = useParams();

  const fetchData = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await SubscriptionServiceApi.getOneSubscription({
          id,
        });
        const resData = response?.data?.data;
        setData(resData);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: id || "",
      subscriptionType: data?.subscriptionType || "",
      price: data?.price || "",
      features: data?.features.length > 0 ? data?.features : [""], // Ensure at least one feature by default
      status: data?.status || "",
    },
    validationSchema: subscriptionValidation,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = id
          ? await SubscriptionServiceApi.updateSubscription(values)
          : await SubscriptionServiceApi.addSubscription(values);
        toast.success(response?.data?.message, { toastId: "success" });
        navigate(-1);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    },
  });
  

  const handleAddDescriptionPoint = () => {
    formik.setFieldValue("features", [...formik.values.features, ""]);
  };

  const handleDescriptionChange = (index, event) => {
    const updatedFeatures = [...formik.values.features];
    updatedFeatures[index] = event.target.value;
    formik.setFieldValue("features", updatedFeatures);
  };

  const handleDeleteDescriptionPoint = (index) => {
    const updatedFeatures = formik.values.features.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("features", updatedFeatures);
  };

  const handlePriceChange = (event) => {
    // Get the current value of the input field
    let value = event.target.value;
  
    // Regular expression to match only numeric values (up to 5 digits and 2 decimal places)
    const regex = /^\d{0,5}(\.\d{0,2})?$/;
  
    // Check if the value matches the regex
    if (regex.test(value)) {
      formik.setFieldValue("price", value);
    }
  };
  


  return (
    <Box>
      <CardLayout>
        <CardHeader title={`${id ? "Edit" : "Add"} Subscription`} />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      label="Subscription Type *"
                      name="subscription"
                      id="subscription-type-label"
                      style={{
                        color:
                          formik.touched.subscriptionType &&
                          formik.errors.subscriptionType
                            ? "#FF4842"
                            : "",
                      }}
                    >
                      Subscription Type *
                    </InputLabel>
                    <Select
                      labelId="subscription-type-label" 
                      id="subscription-select"
                      label="Subscription Type *"
                      value={formik.values.subscriptionType}
                      onChange={(e) =>
                        formik.setFieldValue("subscriptionType", e.target.value)
                      }
                      error={Boolean(
                        formik.touched.subscriptionType &&
                          formik.errors.subscriptionType
                      )}
                    >
                      {SubscriptionTypeOptions.map((option) => (
                        <MenuItem value={option.value} key={option._id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.subscriptionType &&
                      formik.errors.subscriptionType && (
                        <FormHelperText error>
                          {formik.errors.subscriptionType}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                <TextField
  name="price"
  fullWidth
  label="Price"
  required
  type="text"  // Change type to text, as we are handling validation manually
  value={formik.values.price}
  onChange={handlePriceChange}
  error={Boolean(formik.touched.price && formik.errors.price)}
  helperText={formik.touched.price && formik.errors.price}
/>

                </Grid>

                <Grid item xs={12}>
                  {formik.values.features.map((point, index) => (
                    <div key={index}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Feature"
                        value={point}
                        onChange={(e) => handleDescriptionChange(index, e)}
                        error={Boolean(formik.touched.features?.[index] && formik.errors.features?.[index])}
                        helperText={
                          formik.touched.features?.[index] && formik.errors.features?.[index]
                            ? formik.errors.features[index]
                            : ""
                        }
                        InputProps={{
                          endAdornment: (
                            formik.values.features.length > 1 && ( // Only show delete button if there are 2 or more description points
                              <InputAdornment position="end">
                                <IconButton onClick={() => handleDeleteDescriptionPoint(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          ),
                        }}
                      />
                    </div>
                  ))}
                  <Button variant="outlined" onClick={handleAddDescriptionPoint}>
                    Add Feature
                  </Button>
                </Grid>

                <Box mt={3} display="flex" justifyContent="center">
                  <LoadingButton
                    size="large"
                    variant="contained"
                    type="submit"
                    loading={loading}
                  >
                    {id ? "Update" : "Add"} Subscription
                  </LoadingButton>
                </Box>
              </Grid>
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
}

export default AddSubscription;