import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogTitle, FormControlLabel, FormGroup, IconButton, Switch, Tooltip} from "@mui/material";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";
import errorHandler from "../../utils/errorHandler";
import { getSrno } from "../../utils/helpers";
import SearchInput from "../../components/SearchInput";
import { toast } from "react-toastify";
import { EditOutlined } from "@mui/icons-material";
import EmailTemplateList from "../../services/emailTemplateLits"
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function EmailTemplateLits() {
  const navigate = useNavigate();
  const [currentStatus, setCurrentStatus] = useState(null);
  const [statusId, setStatusId] = useState("");
  const [show, setShow] = useState(false);
  const [warning, setWarning] = useState("");
  const [action, setAction] = useState("");

  const [paginData, setPaginData] = useState({
    totalCount: 0,
    data: [],
  });
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    sortObject: { createdAt: -1 },
    pageSize: 10,
    pageNumber: 1,
    searchItem: "",
    status: [],
    sortBy: "",   
  });

  const fetchInstitutionList = async () => {
    try {
      setLoading(true);
      let res = await EmailTemplateList.getAll(payload);
      console.log()
      setPaginData({
        data: res?.data?.data.result, 
        totalCount: res?.data?.data.totalRecords,
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInstitutionList();
  }, [payload]);


  const deleteData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#107491",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await EmailTemplateList.delete({ id });
          if (res?.status === 200) {
            toast.success(res?.data?.message, { toastId: "success" });
            setPaginData((prev) => {
              const updatedData = (prev.data || []).filter((item) => item._id !== id);
              return {
                ...prev,
                data: updatedData,
                totalCount: prev.totalCount - 1,
              };
            });
          } else {
            toast.error(res?.data?.message, { toastId: "error" });
          }
        } catch (error) {
          console.error("Error deleting...", error);
          errorHandler(error);
        }
      }
    });
  };
  
 
  const columns = [
    {
      name: "Sr. No.",
      cell: (row, index) =>
        getSrno(payload.pageNumber, payload.pageSize, index),
      grow: 0.6,
    },
    {
      name: "Template Name",
      sortField: "title",
      sortable: true,
      selector: (row) => row?.title,
    },
    {
      name: "Subject",
      sortField: "subject",
      sortable: true,
      selector: (row) => row?.subject,
    },
    {
      name: "Created date",
      sortField: "createdAt",
      sortable: true,
      selector: (row) => (row?.createdAt ? new Date(row.createdAt).toLocaleDateString("en-US") : "NA"),
     
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={row.status === 1}
                  onClick={() => {
                    setShow(true);
                    setCurrentStatus(row.status);
                    setStatusId(row._id);
                    setAction("status");
                    setWarning(
                      `Are you sure you want to ${
                        row.status === 1 ? "deactive" : "activate"
                      } the Email Template?`
                    );
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </FormGroup>
        </>
      ),
    },
    {
      name: "Action",
      right: true,
      cell: (row) => (
        <>
          <Tooltip title="Edit" placement="left">
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/email-template-management/edit/${row._id}`);
              }}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>

          <Tooltip title="View" placement="top" leaveDelay={50}>
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/email-template-management/view/${row._id}`);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete" placement="top">
            <IconButton color="primary" onClick={() => deleteData(row?._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];
  const handleSort = (column, sortDirection) => {
    const srt = {
      [column.sortField]: sortDirection === "asc" ? -1 : 1
    };
    setPayload((prev) => ({
      ...prev,
      sortObject: srt
    }));
  };

  const toggleChecked = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus ? 0 : 1;
  
      const res = await EmailTemplateList.changeStatus({
        id,
        status: newStatus,
      });
  
      setCurrentStatus(""); // Reset currentStatus
  
      if (res.status === 200 && res?.data?.message) {
        toast.success(res?.data?.message, { toastId: "success" });
  
        // Update the status of the specific row immediately
        setPaginData((prev) => {
          const updatedData = prev.data.map(item => 
            item._id === id ? { ...item, status: newStatus } : item
          );
          return {
            ...prev,
            data: updatedData,
          };
        });
        
        setShow(false);
      } else {
        toast.error(res?.data?.message, { toastId: "error" });
        setShow(false);
      }
    } catch (err) {
      setShow(false);
      errorHandler(err);
    }
  };

  const handleClose = () => setShow(false);
  

  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: "h4" }}
        title="Email Template Management"
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <SearchInput data={payload} setSearch={setPayload} />
        <Button
          size="large"
          type="submit"
          variant="contained"
          sx={{ ml: 3, mr: 3 }}
          onClick={() => navigate("/email-template-management/add")}
        >
          Add
        </Button>
        
      </Box>
      <CardContent>
        <DataTable
          customStyles={{
            headCells: {
              style: {
                fontSize: "15px",
                fontWeight: "bold",
              },
            },
          }}
          columns={columns}
          data={paginData?.data}
          pagination
          paginationServer
          paginationTotalRows={paginData?.totalCount}
          onChangePage={(page) =>
            setPayload((prev) => ({ ...prev, pageNumber: page }))
          }
          paginationPerPage={payload.pageSize}
          paginationRowsPerPageOptions={[2,5, 10, 20, 50]}
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
            setPayload((prev) => ({
              ...prev,
              pageSize: currentRowsPerPage,
              pageNumber: currentPage,
            }));
          }}
          progressPending={loading}
          onSort={handleSort}
          progressComponent={<CustomLoader />}
        />
      </CardContent>

      <Dialog
        open={show}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle as="h3">{warning}</DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              if (action === "status") {
                toggleChecked(statusId, currentStatus); // Pass the id and current status
              }
            }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
