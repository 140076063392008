import AxiosInterceptor from "../utils/AxiosInterceptor";

const EmailTemplateList = {
    getAll: (value) => AxiosInterceptor().post("/getAllTemplate", value),
    edit: (value) => AxiosInterceptor().post("/updateTemplate", value),
    getById: (value) => AxiosInterceptor().get(`/getOneTemplate/${value?.id}`),
    add: (value) => AxiosInterceptor().post("/addTemplate", value),
    delete: (value) => AxiosInterceptor().get(`/deleteTemplate/${value?.id}`),
    sendMail: (value) => AxiosInterceptor().post("/sendEmailVal", value),
    changeStatus: (value) => AxiosInterceptor().post("changeStatusEmailTemplate", value),
    // getDropdownLanguages: (value) =>
    // AxiosInterceptor().post("/get-all-course-entity-without-pagination", value),
};

export default EmailTemplateList;