import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import GlobalSetting from "../pages/Global Setting";
import ManageAndAccessUserAccounts from "../pages/Manage-and-access-user-accounts";
import EmailTemplate from "../pages/emailTemplate/List";
import EditAddEmail from "../pages/emailTemplate/Edit";
import ViewEmailTemplate from "../pages/emailTemplate/View";
import QuestionList from "../pages/Questionmanagemnt"
import VirtualGift from "../pages/virtualGifts";
import AddVirtualGift from "../pages/virtualGifts/Add";
import VirtualGiftView from "../pages/virtualGifts/view";
import Subscription from "../pages/SubscriptionPlan";
import AddSubscription from "../pages/SubscriptionPlan/Add";
import SubscriptionView from "../pages/SubscriptionPlan/View";
import UserAttributes from "../pages/ProfileAttribute";
import AddEditProfileAttribute from "../pages/ProfileAttribute/Add";

const ChangePassword = lazy(() => import("../pages/auth/ChangePassword"));
const MyAccount = lazy(() => import("../pages/myAccount/MyAccount"));
const PrivateLayout = lazy(() => import("../layouts/private"));
const Page404 = lazy(() => import("../pages/error/Page404"));
const DashboardPage = lazy(() => import("../pages/dashboard/DashboardPage"));

const CmsPage = lazy(() => import("../pages/cms/CmsPage"));
const ViewCms = lazy(() => import("../pages/cms/ViewCms"));
const AddEditCms = lazy(() => import("../pages/cms/AddEditCms"));

// const CompanyRegisterForm = lazy(() => import("../pages/userCompanyform"));
const LoadingFallback = <div>Loading...</div>;
const makeLazyComponent = (importFunc) => (
    <Suspense fallback={LoadingFallback}>{importFunc()}</Suspense>
);

const MainRouting = () => {
  const routes = [
    {
      element: <PrivateLayout />,
      errorElement: <Page404 />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        {
          path: "dashboard",
          element: makeLazyComponent(() => <DashboardPage />),
        },

        {
          path: "reset-password",
          element: makeLazyComponent(() => <ChangePassword />),
        },
        { path: 'global-setting', element:<GlobalSetting />}, 
        {
          path: "profile",
          element: makeLazyComponent(() => <MyAccount />),
        },
        {
          path: "virtual-gifts",
          children: [
            { index: true, element: <VirtualGift /> },
            { path: "add", element: <AddVirtualGift /> },
            { path: "edit/:id", element: <AddVirtualGift /> },
            { path: "view/:id", element: <VirtualGiftView /> },
          ],
        },
        {
          path: "subscription-plan",
          children: [
            { index: true, element: <Subscription /> },
            { path: "add", element: <AddSubscription /> },
            { path: "edit/:id", element: <AddSubscription /> },
            { path: "view/:id", element: <SubscriptionView /> },
          ],
        },
       
        {
          path: "manage-and-access-user-accounts",
          children: [
            { index: true, element: <ManageAndAccessUserAccounts /> },
            // { path: "add", element: <AddCustomer /> },
            // { path: "edit/:id", element: <EditCustomer /> },
          ],
        },
        {
          path: "user-profile",
          children: [
            { index: true, element: <UserAttributes /> },
            { path: "add", element: <AddEditProfileAttribute /> },
            { path: "edit/:id", element: <AddEditProfileAttribute /> },
          ],
        },
        {
          path: "question-management",
          children: [
            { index: true, element: <QuestionList /> },
            // { path: "add", element: <AddCustomer /> },
            // { path: "edit/:id", element: <EditCustomer /> },
          ],
        },
        {
          path: "email-template-management",
          children: [
            { index: true, element: <EmailTemplate /> },
            { path: "add", element: makeLazyComponent(() => <EditAddEmail />) },
            { path: "edit/:id", element: makeLazyComponent(() => <EditAddEmail />) },
            { path: "view/:id", element: makeLazyComponent(() => <ViewEmailTemplate />), },
          ],
        },

        {
          path: "cms",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <CmsPage />),
            },
            {
              path: "add",
              element: makeLazyComponent(() => <AddEditCms />),
            },

            {
              path: "edit/:id",
              element: makeLazyComponent(() => <AddEditCms />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewCms />),
            },
          ],
        },
       
      ],
    },
    { path: "*", element: <Navigate to="/dashboard" /> },
  ];
  return useRoutes(routes);
};

export default MainRouting;