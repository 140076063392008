import * as Yup from "yup";
import {
  EMAIL_REGEX,
  MOBILE_REGEX,
  NOSPACE,
  NOSPACE_REGEX,
  PASSWORDS_REGEX,
} from "./constants";
import { MESSAGE } from "./validationMessage";

let email = Yup.string()
  .max(60, "Email should not be longer than 60 characters")
  .required(MESSAGE.REQUIRED)
  .email(MESSAGE.EMAIL)
  .matches(EMAIL_REGEX, MESSAGE.EMAIL);

let name = Yup.string()
  .max(60, "Name should not be longer than 60 characters")
  .required(MESSAGE.REQUIRED)
  .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE);

let title = Yup.string()
  .max(60, "This field should not be longer than 60 characters")
  .required(MESSAGE.REQUIRED)
  .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE);

let description = Yup.string()
  .max(250, "Description should not be longer than 250 characters")
  .required(MESSAGE.REQUIRED)
  .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE);

let question = Yup.string()
  .max(150, "This field should not be longer than 150 characters")
  .required(MESSAGE.REQUIRED)
  .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE);

let status = Yup.mixed().required(MESSAGE.REQUIRED);

let mobile = Yup.string().matches(MOBILE_REGEX, MESSAGE.PHONE);

export const CreatorAddSchema = Yup.object({
  name,
  email,
  mobile,
  password: Yup.string()
    .required(MESSAGE.REQUIRED)
    .matches(PASSWORDS_REGEX, MESSAGE.PASSWORD),
  cPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], MESSAGE.CPASSWORD)
    .required(MESSAGE.REQUIRED),
});

export const CreatorEditSchema = Yup.object({
  question: Yup.string().required(MESSAGE.REQUIRED),
  slug: Yup.string().required(MESSAGE.REQUIRED),
});
export const TaskSchema = Yup.object({
  title,
  description,
  status,
  languageId: Yup.string().required(MESSAGE.REQUIRED),
});
export const LanguageSchema = Yup.object({
  title,
  status,
});
export const CmsSchema = Yup.object({
  title,
  status,
  description: Yup.string()
  .test(
    "not-empty-html",
    "Description cannot be empty",
    (value) => {
      if (!value || value.trim() === "") return false;
      const strippedValue = value.replace(/<[^>]+>/g, "").trim(); 
      return strippedValue !== ""; 
    }
  )
      .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
      .required("Description is required")
  .min(1, "At least one description point is required"),
  contentType: Yup.string()
    .max(30, "Slug should not be longer than 30 characters")
    .required(MESSAGE.REQUIRED)
    .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
});
export const QuestionanaireSchema = Yup.object({
  fieldType: Yup.string().required(MESSAGE.REQUIRED),
  question: Yup.string().required("Question is required"),
  options: Yup.array().of(
    Yup.object({
      label: Yup.string().when("$fieldType", {
        is: "SelectBox",
        then: (schema) =>
          schema
            .max(20, "Label should not be longer than 20 characters")
            .required(MESSAGE.REQUIRED)
            .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
        otherwise: (schema) => schema.notRequired(),
      }),
      value: Yup.string().when("$fieldType", {
        is: "SelectBox",
        then: (schema) =>
          schema
            .max(20, "Value should not be longer than 20 characters")
            .required(MESSAGE.REQUIRED)
            .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
        otherwise: (schema) => schema.notRequired(),
      }),
    })
  ),
});

export const EmailTemplateSchema = Yup.object({
  title: Yup.string()
    .min(2, "Title must be longer than 2 characters")
    .max(100, "Title should not be longer than 100 characters")
    .required("Title is required")
    .matches(NOSPACE_REGEX, "Title cannot start or end with a blank space."),
  subject: Yup.string()
    .min(2, "Subject must be longer than 2 characters")
    .max(100, "Subject should not be longer than 100 characters")
    .required("Subject is required")
    .matches(NOSPACE_REGEX, "Subject cannot start or end with a blank space."),
  slug: Yup.string()
    .min(2, "Slug must be longer than 2 characters")
    .max(100, "Slug should not be longer than 100 characters")
    .required("Slug is required")
    .matches(/^[a-zA-Z0-9]+(?:[-_][a-zA-Z0-9]+)*$/, "Slug can only contain letters, numbers, and hyphens, and cannot start or end with a hyphen"),
   content: Yup.string()
    .min(10, "Description must be longer than 10 characters")
    .max(500, "Description should not be longer than 500 characters")
    .required("Description is required")
    .matches(NOSPACE_REGEX, "Description cannot start or end with a blank space."),
  status: Yup.string()
    .required("Status is required"),

})

export const category = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Name should be at least 3 characters long")
    .max(50, "Name should be at most 50 characters long")
    .matches(NOSPACE_REGEX, "Name cannot start with a blank space"),

  coins: Yup.number()
    .required("Coins is required")
    .test('min-value', 'Coins must be at least 0.1', value => parseFloat(value) >= 0.1)
    .test('max-value', 'Coins must be at most 10000', value => parseFloat(value) <= 10000)
    .typeError("Only numbers are allowed"),
  
  // coins: Yup.string()
  //   .matches(
  //     /^[0-9]{0,6}(\.[0-9]{0,2})?$/,
  //     "Coins must be a number with at most 6 digits and 2 decimal places"
  //   )
    
  //   .required("Coins is required"),

  Giftimage: Yup.mixed()
    .nullable()
    .test("image", "Image is required", function (value) {
      const { imagePreview } = this.parent;
      return !!imagePreview || value != null;
    }),
});

// export const subscriptionValidation = Yup.object().shape({
//   price: Yup.string()
//     .matches(/^\d+$/, "Price must be a number")
//     .required("Price is required")
//     .min(0, "Price must be greater than or equal to 0"),
//   subscriptionType: Yup.string().required("Subscription Type is required"),
//   descriptionPoints: Yup.array()
//     .of(Yup.string().required("Description cannot be empty"))
//     .min(1, "At least one description point is required"),
// });

export const subscriptionValidation = Yup.object().shape({
  price: Yup.number()
    .min(0.01, "Price must be greater than or equal to 0.01")
    .max(10000, "Price must be less than or equal to 1000")
    .required("Price is required"),
  subscriptionType: Yup.string().required("Subscription Type is required"),
  features: Yup.array()
    .of(
      Yup.string()
        .max(50, "feature should be at most 50 characters long")
        .matches(NOSPACE_REGEX, "feature cannot start with a blank space")
        .required("feature cannot be empty")
    )
    .min(1, "At least one feature point is required"),
});

export const ProfileAttribute = Yup.object({
  title: Yup.string()
    .min(3, "Title must be at least 3 characters")
    .max(20, "Title cannot be longer than 20 characters")
    .required("Title is required")
    .matches(NOSPACE_REGEX, NOSPACE),
})