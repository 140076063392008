import React from "react";
import Attributes from "./CommonComponent";

const UserAttributes = () => {
  const sections = [
    { slug: "eye-color", title: "Eye Color" },
    { slug: "hair-color", title: "Hair Color" },
    { slug: "beard-type", title: "Beard Type" },
    { slug: "skin-color", title: "Skin Color" },
    { slug: "body-type", title: "Body Type" },
    { slug: "personality", title: "Personality" },
    { slug: "love-language", title: "Love Language" },
    { slug: "things-like", title: "Things I Like" },
  ];

  return (
    <>
      {sections.map((section, index) => (
        <Attributes key={index} slug={section.slug} title={section.title} />
      ))}
    </>
  );
};

export default UserAttributes;
